import { usePunditUserContext } from "@circle-react/contexts";
import { useIsMarketingHubStatusVisible } from "./useIsMarketingHubStatus";

export const useIsNonMemberContactsEnabled = () => {
  const { currentCommunitySettings } = usePunditUserContext();
  const isMarketingHubStatusVisible = useIsMarketingHubStatusVisible();

  return (
    isMarketingHubStatusVisible &&
    Boolean(currentCommunitySettings?.allow_non_member_contacts)
  );
};
