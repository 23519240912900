import { useGetMarketingHubStatus } from "./useGetMarketingHubStatus";

export const useIsMarketingHubStatusEnabled = () => {
  const { data } = useGetMarketingHubStatus();

  return Boolean(data?.enabled);
};

export const useIsMarketingHubStatusVisible = () => {
  const { data } = useGetMarketingHubStatus();

  return Boolean(data?.visible);
};

export const useIsMarketingHubStatusLoading = () => {
  const { isLoading } = useGetMarketingHubStatus();

  return isLoading;
};
